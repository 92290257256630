import { isTeamMember } from "~/utils/roles";

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp();

  const { teamMember, userRoles, refetch, isSlackIdSet, isExternalCsm } = useUserMe();
  const { getAndUpdateUserSlackId } = useSlackApi();
  const { isDevelopment } = useFeatureFlag();
  const { addToastWarning } = useToast();

  // If we are hydrating (then first load) we need to load the informations
  if (nuxtApp.isHydrating) await refetch();

  // If data corrupted or user has been offboarded
  if (!isTeamMember(userRoles.value) || teamMember.value?.offboarded_at) return navigateTo("/403");

  // If user is a team member but has no team member row then he need to verify his informations
  // If user is a team member but has no other roles (like CSM, consultant or accounting) redirect to onboarding
  if (!teamMember.value?.is_onboarded) return navigateTo("/onboarding");

  // If user is a team member but has no slack id set, we fetch it
  // (only if not hydrating, cause we use notify and it's available after first load)
  // (in development if you want to have the slack id set, you can use the /profile page)
  if (!isDevelopment && !nuxtApp.isHydrating && !isSlackIdSet.value) {
    getAndUpdateUserSlackId();
  }

  const authorizedRoutes = [
    "/dashboard",
    "/ppe-requests",
    "/qualification-training-requests",
    "/professional-card-requests",
    "/medical-checkup-requests",
  ];

  if (isExternalCsm.value && !authorizedRoutes.some((route) => to.path.startsWith(route))) {
    addToastWarning({ description: "Vous n'êtes pas autorisé à accéder à la page." });
    return navigateTo("/");
  }
});
